.login-card {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 10px rgba(0, 0, 0, 0.075);
  font-weight: bold;
}
#root {
  min-width: 1200px !important;
}
.mt-4 .btn {
  font-weight: bold;
  color: #e21251;
  background-color: transparent;
  border: 2px solid #e21251;
}

.login-card a {
  color: #e21251;
  text-decoration: none;
}

.option:focus {
  background-color: #e21251;
  color: white;
}
.dark {
  color: darkgray;
}
.dark:hover {
  color: black;
}
.padding-24{
    padding: 24px;
}
.width-125 {
  width: 125px;
}
.width-20 {
  width: 20px;
}
